import React from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './global/styles/global';
import { main } from './global/styles/themes';
import * as S from './global/styles/styles';

const Header = React.lazy(() => import('./components/Header'));
const Home = React.lazy(() => import('./pages/Home'));
const Footer = React.lazy(() => import('./components/Footer'));

function App() {
  return (
    <ThemeProvider theme={main}>
      <S.Container>
        <GlobalStyles />
        <Header />
        <Home />
        <Footer />
      </S.Container>
    </ThemeProvider>
  );
}

export default App;
