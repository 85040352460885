import { DefaultTheme } from 'styled-components';

export const main: DefaultTheme = {
  primary: '#152c3e',
  secondary: '#38B6FF',
  tertiary: '#708592',

  red: '#ba0b0b',
  gray: '#dedede',
  black: '#080808',
  white: '#f7f7f7',

  heading: '#212529',
  paragraph: '#343A40',
  background: '#F8F9FA',
};
